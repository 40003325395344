import React from "react"

function CommentBox(props) {

  const backgroundColor = ['#e3698c', '#e88902', '#6ca001', '#3c78d7', '#dc0067']
  const currentColor = backgroundColor[props.index]
  // console.log(props.feed.gram_feed)
  const isGramFeed = () => {
    if (props.feed.feedback !== "") {
      return (
        <>
          <div className="comment-point" style={{ marginTop: "6px", marginRight: "10px", backgroundColor: backgroundColor[props.index + 1] }}> 피드백 </div>
          <div className="received-text" style={{ color: "#343841" }}> {props.feed.feedback} </div>
        </>
      )
    } else {
      return (<span />)
    }
  }

  return (
    <div className="comment-box received-text">
      <div className="comment-point" style={{ marginTop: "6px", marginRight: "10px", backgroundColor: currentColor }}> 첨삭포인트 {props.index + 1} </div>
      <div className="received-conetent">
        <div className="received-text"> <span style={{ color: "red" }}> {props.feed.orin_word} </span> {"->"} {props.feed.cor_word} </div>
        <div className="received-text" style={{ color: "#343841" }}> {props.feed.error_type} </div>
        {isGramFeed()}
      </div>
    </div>
  );
};

export default CommentBox;