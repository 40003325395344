import React, { useState, useRef, useEffect } from 'react';
import './Chat.css';
import Message from '../components/Message';
import axios from 'axios';

import Header from '../components/header';
import checkContainsKorean from '../components/utils/CheckContainsKorean';

function Chat() {
  const [messages, setMessages] = useState([{
    ...{
      text: "테스트 할 영어 문단을 입력해 주세요.",
    }, type: 'start'
  }]);
  const [InputText, setInputText] = useState('');
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const endOfMessageRef = useRef(null);

  useEffect(() => {
    endOfMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendClick();
      inputRef.current.focus();
    }
  };

  const handleSendClick = async () => {
    if (!InputText || loading) return;
    if (InputText.length < 2) { setInputText(''); return; }
    let newMessage = {
      prompt: InputText,
    };
    setInputText('');
    setLoading(true);
    setMessages(messages => messages.concat({ ...newMessage, type: 'sent' }));

    if (checkContainsKorean(InputText)) {
      setMessages(messages => messages.concat({ text: '영어로 입력해 주세요.', type: 'start' }));
      setLoading(false);
    }
    else {
      try {
        const response = await axios.post(
          'https://poly.tutorusresearch.com/api/writing',
          newMessage
        )
        console.log(response.data)
        setMessages(messages => messages.concat({ text: response.data, type: 'received' }));
        setLoading(false);

      } catch (error) {
        setLoading(false);
        console.error('There was an error sending the message:', error);
      }
    }

  };


  return (
    <div>
      <Header />
      <div className='chat-container'>
        <div className='message-container'>
          {messages.map((message, index) => (
            <span key={index}>
              <Message message={message} />
            </span>
          ))}
          {loading && <p className='message received'>Loading...</p>}
          <div ref={endOfMessageRef} />
        </div>
        <div className='input-area'>
          <input
            ref={inputRef}
            className='input'
            type="text"
            value={InputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleKeyPress}
            disabled={loading}
          />
          <button type="submit" onClick={handleSendClick} disabled={loading} className='button'>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default Chat;
