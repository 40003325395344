import './App.css';
import Chat from './pages/Chat';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
      <Router>
        <Routes>
          <Route path="/grammar" element={<Chat/>} />
        </Routes>
      </Router>
  );
}

export default App;
