import React from "react";
import ReceivedMessage from "./RecivedMessage";
import cat from "./images/cat.png"

function Message(props) {

  let messageSellector = {
    sent: <div className={'message sent'}>{props.message.prompt}</div>,
    audio: <audio className={'message'} autoPlay controls src={props.message.audioURL} />,
    start: <div style={{ display: "flex" }}>
      <img height={"50px"} src={cat} style={{ margin: "10px", marginLeft: "-10px" }} />
      <div className={'message received'}>{props.message.text}</div>
    </div>,
    received: <ReceivedMessage messages={props.message} />
  };
  return (
    <div>{messageSellector[props.message.type]}</div>
  );
};

export default Message;