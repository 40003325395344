import React from 'react';

const HighlightText = ({ text, grammar, color }) => {
  // 단어별로 텍스트를 분할
  const words = text.split(' ');
  let highlightedText = null;
  // 단어를 검사하여 하이라이팅 적용

  let orinStart = [];
  let corIdxs = [];
  let wrongWords = [];

  grammar.map((feed, index) => {
    for (var i = feed.orin_start; i <= feed.orin_end; i++) {
      orinStart.push(i);
    }
    for (var i = feed.cor_start; i <= feed.cor_end; i++) {
      corIdxs.push(i);
    }
    wrongWords.push(feed.orin_word);
  })
  if (color === "red") {
    highlightedText = words.map((word, index) => {
      const key = `${word}-${index}`;
      if (orinStart.includes(index) && (wrongWords[index] !== '')) {
        return <span key={key} style={{ color: color }}>{word} </span>;
      }
      return <span key={key}>{word} </span>;
    });
  } else {
    highlightedText = words.map((word, index) => {
      const key = `${word}-${index}`;
      if (corIdxs.includes(index)) {
        return <span key={key} style={{ color: color }}>{word} </span>;
      }
      return <span key={key}>{word} </span>;
    });
  }


  return <div className="received-text received-conetent">{highlightedText}</div>;
};

export default HighlightText;
