import React from "react";
import cat from "./images/cat.png"
import robot from "./images/robot.png";
import CommentBox from "./CommentBox";
import HighlightText from "./HighlightText";


function SentenceComponent(props) {

  if (props.message.Grammar.length === 0) {
    return (
      <div style={{ display: "flex" }}>
        <div className={'message received received-conetent'}>
          <div />
          <div>{props.message.Input.Text}</div>
          <div>잘 했어! 수정할게 없네!</div>
        </div>
      </div>
    )
  } else {
    props.setMessageShow(true)
  }

  return (
    <div>
      <HighlightText className="received-text" text={props.message.Input.Text} grammar={props.message.Grammar} color={"red"} />
      {props.message.Grammar.map((feed, index) => (
        <span key={index}>
          <CommentBox feed={feed} index={index} />
        </span>
      ))}
      <div className="comment-box received-text" style={{ backgroundColor: "white" }}>
        <img height={"30px"} src={robot} className="received-text" />
        <div className="received-conetent">
          <div className="received-text" style={{ color: "#4f4f50" }}>수정된 문장</div>
          <HighlightText className="received-text" text={props.message.Result.Text} grammar={props.message.Grammar} color={"#3e80c7"} />
        </div>
      </div>
    </div>
  )
}

export default SentenceComponent;