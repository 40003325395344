import React, { useState, useEffect } from "react";

import cat from "./images/cat.png";
import SentenceComponent from "./SentenceComponent";

function ReceivedMessage(props) {
  const [messageShow, setMessageShow] = useState(false);

  useEffect(() => {
    messageToShow()
  }, [messageShow])

  const handleMessageShow = () => {
    setMessageShow(true)
  }

  const messageToShow = () => {
    if (messageShow) {
      return (
        <div className="received-text">네가 쓴 글을 더 매끄럽게 내가 수정해볼게.</div>
      );
    }
  }

  return (
    <div style={{ display: "flex" }}>
      <img height={"50px"} src={cat} style={{ margin: "10px", marginLeft: "-10px" }} />
      <div className={'message received received-conetent'}>
        {messageToShow()}
        {
          props.messages.text.map((message, index) => (
            <span key={index}>
              <SentenceComponent message={message} setMessageShow={setMessageShow} />
            </span>
          ))
        }
      </div>
    </div>
  );
};

export default ReceivedMessage;